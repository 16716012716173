import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { PressKitSection } from 'src/sections';
import { Page, Section, PressPost } from 'src/components';

const QUERY = graphql`
  query {
    allGraphCmsMention(sort: { fields: date, order: DESC }) {
      nodes {
        id
        title
        url
        outlet
        date
      }
    }
    allGraphCmsPost(
      filter: { displayInNewsroom: { eq: true } }
      sort: { fields: postDate, order: DESC }
    ) {
      nodes {
        title
        subtitle
        vertical
        slug
        teaserTldr
        externalUrl
        postDate
      }
    }
  }
`;

const Press = () => {
  const data = useStaticQuery(QUERY);
  const mentions = data?.allGraphCmsMention?.nodes;
  const posts = data?.allGraphCmsPost?.nodes;

  return (
    <Page
      title="Press"
      description="Kits and mentions"
      path="press"
      image="/img/og/default.png"
      color="#F4F3F7"
      darkColor="#262527"
    >
      <PressKitSection />
      {posts.length > 0 && (
        <Section narrow small color="var(--g-cerise)" title="Announcements" id="announcements">
          {posts?.map((post) => (
            <PressPost
              key={post.id}
              link={post.externalUrl || '/post/' + post.slug}
              date={post.postDate}
              title={post.title}
              subtitle={post.subtitle}
            />
          ))}
        </Section>
      )}
      {mentions.length > 0 && (
        <Section narrow small color="var(--g-cerise)" title="Mentions" id="mentions">
          {mentions?.map((post) => (
            <PressPost
              key={post.id}
              link={post.url}
              date={post.date}
              title={post.title}
              subtitle={post.subtitle}
              outlet={post.outlet}
            />
          ))}
        </Section>
      )}
    </Page>
  );
};

export default Press;
